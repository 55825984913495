import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, Input, NgModule } from '@angular/core';
const _c0 = ["*"];
class TuiRadioGroupComponent {
  constructor() {
    this.name = `tui-radio-group-${TuiRadioGroupComponent.index++}`;
  }
}
TuiRadioGroupComponent.index = 0;
TuiRadioGroupComponent.ɵfac = function TuiRadioGroupComponent_Factory(t) {
  return new (t || TuiRadioGroupComponent)();
};
TuiRadioGroupComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TuiRadioGroupComponent,
  selectors: [["tui-radio-group"]],
  inputs: {
    name: "name"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function TuiRadioGroupComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiRadioGroupComponent, [{
    type: Component,
    args: [{
      selector: 'tui-radio-group',
      templateUrl: './radio-group.template.html',
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, {
    name: [{
      type: Input
    }]
  });
})();
class TuiRadioGroupModule {}
TuiRadioGroupModule.ɵfac = function TuiRadioGroupModule_Factory(t) {
  return new (t || TuiRadioGroupModule)();
};
TuiRadioGroupModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiRadioGroupModule
});
TuiRadioGroupModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiRadioGroupModule, [{
    type: NgModule,
    args: [{
      declarations: [TuiRadioGroupComponent],
      exports: [TuiRadioGroupComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiRadioGroupComponent, TuiRadioGroupModule };
