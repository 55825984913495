import * as i0 from '@angular/core';
import { ChangeDetectorRef, Component, ChangeDetectionStrategy, Optional, Self, Inject, ViewChild, Input, HostBinding, NgModule } from '@angular/core';
import * as i3 from '@angular/forms';
import { NgControl } from '@angular/forms';
import * as i2 from '@taiga-ui/cdk';
import { tuiCreateToken, tuiProvideOptions, AbstractTuiNullableControl, TUI_DEFAULT_IDENTITY_MATCHER, tuiIsNativeFocused, tuiAsFocusableItemAccessor, tuiAsControl, TuiCheckedModule, TuiFocusableModule, TuiFocusedModule, TuiFocusVisibleModule } from '@taiga-ui/cdk';
import * as i4 from '@taiga-ui/kit/components/radio-group';
import { TuiRadioGroupComponent } from '@taiga-ui/kit/components/radio-group';
import * as i1 from '@taiga-ui/core';
import { TuiAppearance, TuiWrapperModule } from '@taiga-ui/core';
import { CommonModule } from '@angular/common';

/** Default values for the checkbox options. */
const _c0 = ["focusableElement"];
const TUI_RADIO_DEFAULT_OPTIONS = {
  size: 'm',
  appearances: {
    unchecked: TuiAppearance.Outline,
    checked: TuiAppearance.Primary
  }
};
/**
 * Default parameters for Radio component
 */
const TUI_RADIO_OPTIONS = tuiCreateToken(TUI_RADIO_DEFAULT_OPTIONS);
function tuiRadioOptionsProvider(options) {
  return tuiProvideOptions(TUI_RADIO_OPTIONS, options, TUI_RADIO_DEFAULT_OPTIONS);
}
class TuiRadioComponent extends AbstractTuiNullableControl {
  constructor(control, cdr, options, radioGroup) {
    super(control, cdr);
    this.options = options;
    this.radioGroup = radioGroup;
    this.identityMatcher = TUI_DEFAULT_IDENTITY_MATCHER;
    this.name = null;
    this.size = this.options.size;
    this.pseudoDisabled = false;
  }
  get appearance() {
    return this.checked ? this.options.appearances.checked : this.options.appearances.unchecked;
  }
  get computedDisabled() {
    return this.disabled || this.pseudoDisabled;
  }
  get nativeFocusableElement() {
    return !this.focusableElement || this.computedDisabled ? null : this.focusableElement.nativeElement;
  }
  get focused() {
    return tuiIsNativeFocused(this.nativeFocusableElement);
  }
  get checked() {
    return this.value === null ? this.item === null : this.item !== undefined && this.item !== null && this.identityMatcher(this.value, this.item);
  }
  get computedName() {
    return this.name || this.radioGroupName || this.controlName || '';
  }
  get isFocusable() {
    return !this.readOnly && this.computedFocusable;
  }
  onChecked(checked) {
    if (checked) {
      this.value = this.item !== undefined ? this.item : this.fallbackValue;
    }
  }
  onFocused(focused) {
    this.updateFocused(focused);
  }
  onFocusVisible(focusVisible) {
    this.updateFocusVisible(focusVisible);
  }
  get radioGroupName() {
    return this.radioGroup === null ? null : this.radioGroup.name;
  }
}
TuiRadioComponent.ɵfac = function TuiRadioComponent_Factory(t) {
  return new (t || TuiRadioComponent)(i0.ɵɵdirectiveInject(NgControl, 10), i0.ɵɵdirectiveInject(ChangeDetectorRef), i0.ɵɵdirectiveInject(TUI_RADIO_OPTIONS), i0.ɵɵdirectiveInject(TuiRadioGroupComponent, 8));
};
TuiRadioComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TuiRadioComponent,
  selectors: [["tui-radio"]],
  viewQuery: function TuiRadioComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c0, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.focusableElement = _t.first);
    }
  },
  hostVars: 3,
  hostBindings: function TuiRadioComponent_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵattribute("data-size", ctx.size);
      i0.ɵɵclassProp("_disabled", ctx.computedDisabled);
    }
  },
  inputs: {
    item: "item",
    identityMatcher: "identityMatcher",
    name: "name",
    size: "size",
    pseudoDisabled: "pseudoDisabled"
  },
  features: [i0.ɵɵProvidersFeature([tuiAsFocusableItemAccessor(TuiRadioComponent), tuiAsControl(TuiRadioComponent)]), i0.ɵɵInheritDefinitionFeature],
  decls: 4,
  vars: 13,
  consts: [["focusableElement", ""], ["tuiWrapper", "", 3, "active", "appearance", "disabled", "focus", "hover", "invalid"], [1, "t-mark"], ["type", "radio", 1, "t-native", 3, "tuiCheckedChange", "tuiFocusedChange", "tuiFocusVisibleChange", "disabled", "id", "tuiChecked", "tuiFocusable"]],
  template: function TuiRadioComponent_Template(rf, ctx) {
    if (rf & 1) {
      const _r1 = i0.ɵɵgetCurrentView();
      i0.ɵɵelementStart(0, "div", 1);
      i0.ɵɵelement(1, "div", 2);
      i0.ɵɵelementStart(2, "input", 3, 0);
      i0.ɵɵlistener("tuiCheckedChange", function TuiRadioComponent_Template_input_tuiCheckedChange_2_listener($event) {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.onChecked($event));
      })("tuiFocusedChange", function TuiRadioComponent_Template_input_tuiFocusedChange_2_listener($event) {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.onFocused($event));
      })("tuiFocusVisibleChange", function TuiRadioComponent_Template_input_tuiFocusVisibleChange_2_listener($event) {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.onFocusVisible($event));
      });
      i0.ɵɵelementEnd()();
    }
    if (rf & 2) {
      i0.ɵɵproperty("active", ctx.pseudoActive)("appearance", ctx.appearance)("disabled", ctx.computedDisabled)("focus", ctx.computedFocusVisible)("hover", ctx.pseudoHover)("invalid", ctx.computedInvalid);
      i0.ɵɵadvance();
      i0.ɵɵclassProp("t-mark_visible", ctx.checked);
      i0.ɵɵadvance();
      i0.ɵɵproperty("disabled", ctx.computedDisabled)("id", ctx.id)("tuiChecked", ctx.checked)("tuiFocusable", ctx.isFocusable);
      i0.ɵɵattribute("name", ctx.computedName);
    }
  },
  dependencies: [i1.TuiWrapperDirective, i2.TuiCheckedDirective, i2.TuiFocusableDirective, i2.TuiFocusedDirective, i2.TuiFocusVisibleDirective],
  styles: ["[_nghost-%COMP%]{font:var(--tui-font-text-s);color:var(--tui-text-01);position:relative;display:block;flex-shrink:0;border-radius:100%}[data-size=m][_nghost-%COMP%]{width:1rem;height:1rem}[data-size=l][_nghost-%COMP%]{width:1.5rem;height:1.5rem}._readonly[_nghost-%COMP%]{pointer-events:none}.t-mark[_ngcontent-%COMP%]{transition-property:transform;transition-duration:var(--tui-duration, .3s);transition-timing-function:ease-in-out;position:absolute;background-color:currentColor;border-radius:100%;transform:scale(0)}.t-mark_visible[_ngcontent-%COMP%]{transform:scale(1)}[data-size=m][_nghost-%COMP%]   .t-mark[_ngcontent-%COMP%]{margin:.25rem;width:.5rem;height:.5rem}[data-size=l][_nghost-%COMP%]   .t-mark[_ngcontent-%COMP%]{margin:.4375rem;width:.625rem;height:.625rem}.t-native[_ngcontent-%COMP%]{padding:0;margin:0;border-width:0;border-radius:inherit;background:none;font-size:inherit;line-height:inherit;font-weight:inherit;color:inherit;caret-color:currentColor;outline:none;-webkit-appearance:none;-moz-appearance:none;appearance:none;word-break:keep-all;-webkit-text-fill-color:currentColor;position:absolute;top:0;left:0;width:100%;height:100%;opacity:0;cursor:pointer}.t-native[_ngcontent-%COMP%]:-webkit-autofill, .t-native[_ngcontent-%COMP%]:-webkit-autofill:hover, .t-native[_ngcontent-%COMP%]:-webkit-autofill:focus{caret-color:var(--tui-base-09);border-radius:inherit;color:inherit!important;background-color:transparent!important;-webkit-text-fill-color:var(--tui-text-01)!important;border-color:var(--tui-autofill);-webkit-box-shadow:0 0 0 100rem var(--tui-autofill) inset!important}@supports (-webkit-touch-callout: none){.t-native[_ngcontent-%COMP%]:active{font-size:1rem}}._disabled[_nghost-%COMP%]   .t-native[_ngcontent-%COMP%]{cursor:default}"],
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiRadioComponent, [{
    type: Component,
    args: [{
      selector: 'tui-radio',
      templateUrl: './radio.template.html',
      styleUrls: ['./radio.style.less'],
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [tuiAsFocusableItemAccessor(TuiRadioComponent), tuiAsControl(TuiRadioComponent)]
    }]
  }], function () {
    return [{
      type: i3.NgControl,
      decorators: [{
        type: Optional
      }, {
        type: Self
      }, {
        type: Inject,
        args: [NgControl]
      }]
    }, {
      type: i0.ChangeDetectorRef,
      decorators: [{
        type: Inject,
        args: [ChangeDetectorRef]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_RADIO_OPTIONS]
      }]
    }, {
      type: i4.TuiRadioGroupComponent,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [TuiRadioGroupComponent]
      }]
    }];
  }, {
    focusableElement: [{
      type: ViewChild,
      args: ['focusableElement']
    }],
    item: [{
      type: Input
    }],
    identityMatcher: [{
      type: Input
    }],
    name: [{
      type: Input
    }],
    size: [{
      type: Input
    }, {
      type: HostBinding,
      args: ['attr.data-size']
    }],
    pseudoDisabled: [{
      type: Input
    }],
    computedDisabled: [{
      type: HostBinding,
      args: ['class._disabled']
    }]
  });
})();
class TuiRadioModule {}
TuiRadioModule.ɵfac = function TuiRadioModule_Factory(t) {
  return new (t || TuiRadioModule)();
};
TuiRadioModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiRadioModule
});
TuiRadioModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, TuiCheckedModule, TuiFocusableModule, TuiFocusedModule, TuiFocusVisibleModule, TuiWrapperModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiRadioModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, TuiCheckedModule, TuiFocusableModule, TuiFocusedModule, TuiFocusVisibleModule, TuiWrapperModule],
      declarations: [TuiRadioComponent],
      exports: [TuiRadioComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TUI_RADIO_DEFAULT_OPTIONS, TUI_RADIO_OPTIONS, TuiRadioComponent, TuiRadioModule, tuiRadioOptionsProvider };
